<template>    
    <div>

        <!--====== FOOTER PART START ======-->
        
        <footer id="footer" class="footer-area pt-120">
            <div class="container">
                <div class="footer-widget pb-100 pt-100">

                    <div class="row" style="height:50px"></div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-8">
                            <div class="footer-about mt-50 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                                <a class="logo" href="#">
                                    <img :src="logo" alt="logo" class="logo-extended">
                                </a>
                                <!--<p class="text">Lorem ipsum dolor sit amet consetetur sadipscing elitr, sederfs diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.</p>-->
                                
                            </div> <!-- footer about -->
                        </div>
                        <div class="col-lg-4 col-md-7 col-sm-7">
                            <div class="footer-link d-flex mt-50 justify-content-md-between">
                                <!-- <div class="link-wrapper wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
                                    <div class="footer-title">
                                        <h4 class="title">Quick Link</h4>
                                    </div>
                                    <ul class="link">
                                        <li><a href="#">Road Map</a></li>
                                        <li><a href="#">Privacy Policy</a></li>
                                        <li><a href="#">Refund Policy</a></li>
                                        <li><a href="#">Terms of Service</a></li>
                                        <li><a href="#">Pricing</a></li>
                                    </ul>
                                </div> --><!-- footer wrapper -->
                                <div class="link-wrapper wow fadeIn" data-wow-duration="1s" data-wow-delay="0.6s">
                                    <div class="footer-title">
                                        <h4 class="title">Resources</h4>
                                    </div>
                                    <ul class="link">
                                        <li><a href="#">Home</a></li>
                                        <li><a href="#analisi">Data Analysis</a></li>
                                        <li><a href="#dev">Development</a></li>
                                        <li><a href="#contatti">Contact Us</a></li>
                                        <!-- <li><a href="#">Privacy</a></li> -->
                                    </ul>
                                </div> <!-- footer wrapper -->
                            </div> <!-- footer link -->
                        </div>
                        <div class="col-lg-4 col-md-5 col-sm-5">
                            <div class="footer-contact mt-50 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                                <div class="footer-title">
                                    <h4 class="title">Contacts</h4>
                                </div>
                                <ul class="contact">
                                    <li>Acidsoft s.r.l unipersonale</li>
                                    <li>+39 329 0973637</li>
                                    <li>P. IVA : 01537110296</li>
                                    <li>Codice interscambio : N92GLON</li>
                                    <li>Via Zona Artigianale 424/5, 45030 <br> Pontecchio Pol. (RO) Italy</li>
                                </ul>
                            </div> <!-- footer contact -->
                        </div>
                    </div> <!-- row -->
                </div> <!-- footer widget -->
                <div class="footer-copyright">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="copyright d-sm-flex justify-content-between">
                                <div class="copyright-content">
                                    <p class="text"> © {{getYear()}} Acidsoft All rights reserved | P. IVA : 01537110296</p>
                                </div> <!-- copyright content -->
                            </div> <!-- copyright -->
                        </div>
                        <div class="col-lg-4  text-center m-auto">
                            <a href="https://www.linkedin.com/company/acidsoft/" target="_blank" style="color:black">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                </svg>
                            </a>
                        </div>
                    </div> <!-- row -->
                </div> <!-- footer copyright -->
            </div> <!-- container -->
            <div id="particles-2"></div>
        </footer>
        
        <!--====== FOOTER PART ENDS ======-->
        
        <!--====== BACK TOP TOP PART START ======-->

        <a href="#" class="back-to-top">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="font-size: 30px;">
                <path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"/>
            </svg>
        </a>

        <!--====== BACK TOP TOP PART ENDS ======-->   
    </div>
</template>    

<script>


import Logo from '../assets/Logo-Acidsoft-min.png';

export default {
    data() {
        return {
            //logo        : window.location.origin + window.location.pathname + Logo,
            logo        : Logo,
        }
    },
    methods : {
        getYear : function(){
            return new Date().getFullYear();
        }
    }
}
</script>
