<template>

    <header class="header-area cd-section visible ">
        <div class="navbar-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav class="navbar navbar-expand-lg">
                            <a class="navbar-brand" href="#">
                                <img :src="logo" alt="Acidsoft" width="40px">
                            </a>
                            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="toggler-icon"></span>
                                <span class="toggler-icon"></span>
                                <span class="toggler-icon"></span>
                            </button>

                            <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                <ul id="nav" class="navbar-nav ml-auto">
                                    <li class="nav-item active">
                                        <a class="page-scroll" href="#home">Home</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="page-scroll" href="#analisi">Data Analysis</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="page-scroll" href="#dev">Development</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="page-scroll" href="#contatti">Contact Us</a>
                                    </li>
                                    <li class="nav-item">
                                        <img :src="flag_it" class="lang-select" alt="Lingua Italiana" width="30px" v-on:click="changeLang('IT')">
                                        <img :src="flag_en" class="lang-select active" alt="Lingua Inglese" width="30px"  v-on:click="changeLang('EN')">
                                    </li>
                                </ul>
                            </div> <!-- navbar collapse -->
                            
                             <!-- <div class="navbar-btn ">
                                    <img :src="flag_it" class="lang-select" alt="Lingua Italiana" width="30px" v-on:click="changeLang('IT')">
                                    <img :src="flag_en" class="lang-select active" alt="Lingua Inglese" width="30px"  v-on:click="changeLang('EN')">
                             </div> -->
                        </nav> <!-- navbar -->
                    </div>
                </div> <!-- row -->
            </div> <!-- container -->
        </div> <!-- navbar area -->
        
        <div id="home" class="header-hero bg_cover" style="background-image: url(template/images/banner-bg.svg)">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="header-hero-content text-center">
                            <h3 class="header-sub-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">{{getLang('_home_motto')}}</h3>
                            <!-- <h4 class="header-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.5s">Innovative Solutions for your data challenges</h4>  -->
                            <!-- <p class="text wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.8s">State of Art customized and integrated Web & Mobile Apps</p> -->

                            <ParticleEffectButton
                                :visible.sync="btnOps.visible"
                                :animating.sync="btnOps.animating"
                                :options="btnOps"
                                cls="btn-cls particles-button hide_mobile"
                                >
                                {{getLang('_home_button_getStarted')}}
                            </ParticleEffectButton>
                            <button class="btn-cls particles-button show_mobile mx-auto"> {{getLang('_home_button_getStarted')}} </button>

                        </div> <!-- header hero content -->
                    </div>
                </div> <!-- row -->
                <div class="row">
                    <div class="col-lg-12">
                        <div class="header-hero-image text-center wow fadeIn" data-wow-duration="1.3s" data-wow-delay="1.4s">
                            <img src="images/udraw/undraw_progressive_app_m9ms.svg" alt="hero">
                        </div> <!-- header hero image -->
                    </div>
                </div> <!-- row -->
            </div> <!-- container -->
            <div id="particles-1" class="particles"></div>
        </div> <!-- header hero -->
    </header>
    

</template>


<style>
    .header-area{
        height          : 100vh
    }
    .particles-button{
        margin-top      : 20px!important;
        padding         : 15px!important;
        font-family     : 'BitDust2'!important;
        border-radius   : 0px!important;
        background      : #3f3d56!important;
    }
    .show_mobile{
        display : none;
        color: white;
        border: none;
    }
    @media screen and ( max-width: 650px){
        .hide_mobile{
            display : none;
        }
        .show_mobile{
            display : block;
        }
    }
</style>

<script>
// Jquery
import $ from 'jquery';

// Logo
import Logo from '../assets/logo.svg';

// Bandiere
//import it  from '../assets/flags/italy.svg';   
//import en  from '../assets/flags/united-kingdom.svg';  
import rect_it  from '../assets/flags/it.svg';   
import rect_en  from '../assets/flags/en.svg';   

// Componente Particle Button
import ParticleEffectButton from "vue-particle-effect-buttons"                              // [https://github.com/dreambo8563/vue-particle-effect-buttons]

export default {

    data() {
        return {
            logo    : window.location.origin + window.location.pathname + Logo,
            flag_it : window.location.origin + window.location.pathname + rect_it,
            flag_en : window.location.origin + window.location.pathname + rect_en,
             btnOps : {
                type: "circle",
                easing: "easeInOutCubic",
                size: 2,
                particlesAmountCoefficient: 3,
                oscillationCoefficient: 5,
                color: function () {
                    return "#3f3d56";
                },
                onComplete: () => {
                    

                    if(!this.btnOps.visible){
                        this.btnOps.complete = true;
                        this.btnOps.visible = !this.btnOps.visible;
                    }

                    var $target    = $($($($("#nav").children()[1]).children()[0]).attr('href'));

                    $('html, body').stop().animate({
                        'scrollTop': $target.offset().top -60
                    }, 1000, 'swing').promise().done(function () {

                        // check if menu is open
                        if ($('body').hasClass('menu-is-open')) {
                            $('.header-menu-toggle').trigger('click');
                        }

                        //window.location.hash = $target;
                    });
                        
                    
                },
                onBegin: () => {
                    
                    window.onscroll = null;
                    //clearInterval(this.$root.timer);
                },
                visible: true,
                animating: false,
            },
        }
    },
    mounted() {
        $(".lang-select").on("click",function(){
            $(this).addClass("active");
        })

        $(".show_mobile").on("click" , function(){

            var $target    = $($($($("#nav").children()[1]).children()[0]).attr('href'));

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top -60
            }, 1000, 'swing').promise().done(function () {

                // check if menu is open
                if ($('body').hasClass('menu-is-open')) {
                    $('.header-menu-toggle').trigger('click');
                }

                //window.location.hash = $target;
            });
        })
    },

    components: {
        ParticleEffectButton
    },

    methods: {
        getLang                         : function(text){
            var _self = this;
            return _self.$root.language_file.[_self.$root.lang][text];
        },

        changeLang                      : function(language){
            var _self = this;
            _self.$root.lang = language;
            $(".lang-select").removeClass("active");

        }
    },
    
}
</script>