<template>
    <div id="analisi"  class="cd-section" style="position: relative;">
        <section class="pt-70" >
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5">
                        <div class="section-title text-center pb-30">
                            <div class="line m-auto"></div>
                            <h3 class="title">{{getLang('_data_titolo') }}</h3>
                            <h4 class="header-title" >{{getLang('_data_motto') }}</h4>
                        </div> <!-- section title -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                            <div class="section-title">
                                <div class="line"></div>
                                <h3 class="title"> {{getLang('_data_titolo_1_1') }}</h3>
                            </div> <!-- section title -->
                            <p class="text">Data Analysis, Data Visualization, Advanced Analytics:</p>
                            <p class="text">{{getLang('_data_motto_1_1') }} <span class="acid_evidenziato" >{{getLang('_data_motto_1_green') }}</span> {{getLang('_data_motto_1_2') }} </p>  
                        </div> <!-- about content -->
                    </div>
                    <div class="col-lg-6">
                        <div class="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                            <img src="images/udraw/data.svg" alt="data">
                        </div> <!-- about image -->
                    </div>
                </div> <!-- row -->
            </div> <!-- container -->
            <div class="about-shape-1">
                <img src="template/images/about-shape-1.svg" alt="shape">
            </div>
        </section>


        <section class="about-area pt-70">
            <div class="about-shape-2">
                <img src="template/images/about-shape-2.svg" alt="shape">
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 order-lg-last">
                        <div class="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                            <div class="section-title">
                                <div class="line"></div>
                                <h3 class="title"> {{getLang('_data_titolo_2_2') }} <span> {{getLang('_data_titolo_2_3') }} </span></h3>
                            </div> <!-- section title -->
                            <p class="text">{{getLang('_data_motto_2') }}</p>  
                            <ul>
                                <li>Qlik</li>
                                <li>PowerBI</li>
                                <li>Tableau</li>
                                <li>Rapidminer</li>
                                <li>Mathematica</li>
                                <li>Python</li>
                            </ul>                     
                            
                        </div> <!-- about content -->
                    </div>
                    <div class="col-lg-6 order-lg-first">
                        <div class="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                            <img src="images/udraw/analysis.svg" alt="shape">
                        </div> <!-- about image -->
                    </div>
                </div> <!-- row -->
            </div> <!-- container -->
        </section>
        
    </div>
</template>

<script>
export default {
    methods: {
        getLang                         : function(text){
            var _self = this;
            return _self.$root.language_file.[_self.$root.lang][text];
        },
    },
}
</script>