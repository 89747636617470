<template>
    <div>
        <Header />

        <!-- <div id="section-switcher" class="pt-70" style="position:relative">
            <div id="tip" class="wow fadeInRightBig" data-wow-duration="1.3s" data-wow-delay=".5s">
                <p class="invader">i</p>
            </div>
            <div class="wow fadeInRightBig acid_tooltip" data-wow-duration="1.3s" data-wow-delay=".7s">
                <h5> {{getLang("tooltip_why")}} </h5>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>

        </div> -->
        
        <Data />
        <Dev />
        <Contatti />
        <Footbar />
    </div>
</template>

<script>

export default {

    methods: {
        getLang                         : function(text){
            var _self = this;
            return _self.$root.language_file.[_self.$root.lang][text];
        },
    }    
}
</script>