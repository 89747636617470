<!-- Modal -->
<template>
    <div class="modal fade" id="acid_modal" tabindex="-1" role="dialog" aria-labelledby="acid_modalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="acid_modalLabel">{{getLang('modal_tooltip_title')}} {{getLang($root.scrollResult.jsonLang)}} {{getLang('modal_tooltip_title_2')}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    {{getLang($root.scrollResult.jsonModalLang)}}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .modal-header{
        border-bottom           : 0px;
    }
    .modal-header > h5 {
        color                   : #c0cd4a!important;
    }
    .modal-content{
        border                  : 4px solid #c0cd4a;
        border-radius           : 0;
        background-color        : #3f3d56;
        font-family             : "Lato", sans-serif;
    }
    .modal-body{
        font-size               : large;
        color                   : white;
    }
    .modal-footer {
        align-self              : center;
        border                  : 0px;
    }
    .close{
        color                   : #fff;
    }
    .btn.btn-secondary{
        color                   : #3f3d56;
        background-color        : #c0cd4a!important;
        border-radius           : 0px;
        font-family             : 'BitDust2';
    }

</style>

<script>
export default {
    methods: {
        getLang                         : function(text){
            var _self = this;
            if(text == null){
                return "";
            }
            return _self.$root.language_file.[_self.$root.lang][text];
        },
    },
    
}
</script>