<template>
    <div id="dev" class="cd-section">
        <section class="pt-70">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5">
                        <div class="section-title text-center pb-30">
                            <div class="line m-auto"></div>
                            <h3 class="title">{{getLang('_dev_titolo') }}</h3>
                            <h4 class="header-title" >{{getLang('_dev_motto') }}</h4>
                        </div> <!-- section title -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                            <div class="section-title">
                                <div class="line"></div>
                                <h3 class="title">{{getLang('_dev_text_0') }} <span> {{getLang('_dev_text_01') }}</span></h3>
                            </div> <!-- section title -->
                            <p class="text">{{getLang('_dev_text_1_1') }} <span class="acid_evidenziato" >{{getLang('_dev_text_1_green') }}</span> {{getLang('_dev_text_1_2') }} </p>
                            <p class="text">{{getLang('_dev_text_2_1') }} <span class="acid_evidenziato" >{{getLang('_dev_text_2_green') }}</span> {{getLang('_dev_text_2_2') }} </p>
                            <p class="text">{{getLang('_dev_text_3') }}</p>
                            <!-- <p class="text">{{getLang('_dev_text_4') }}</p>
                            <ul>
                                <li>{{getLang('_dev_app_realizzate_1') }}</li>
                                <li>{{getLang('_dev_app_realizzate_2') }}</li>
                                <li>{{getLang('_dev_app_realizzate_3') }}</li>
                                <li>{{getLang('_dev_app_realizzate_4') }}</li>
                                <li>{{getLang('_dev_app_realizzate_5') }}</li>
                                <li>{{getLang('_dev_app_realizzate_6') }}</li>
                                <li>{{getLang('_dev_app_realizzate_7') }}</li>
                                <li>{{getLang('_dev_app_realizzate_8') }}</li>
                                <li>{{getLang('_dev_app_realizzate_9') }}</li>
                                <li>{{getLang('_dev_app_realizzate_10') }}</li>
                                <li>{{getLang('_dev_app_realizzate_11') }}</li>
                            </ul>
                            <p class="text">{{getLang('_dev_text_5') }}</p> -->
                        </div> <!-- about content -->
                    </div>
                    <div class="col-lg-6">
                        <div class="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                            <img src="images/udraw/undraw_two_factor_authentication_namy.svg" alt="data">
                        </div> <!-- about image -->
                    </div>
                </div> <!-- row -->
            </div> <!-- container -->
            <div class="about-shape-1">
                <img src="template/images/about-shape-1.svg" alt="shape">
            </div>
        </section>


        <section class="about-area pt-70">
            <div class="about-shape-2">
                <img src="template/images/about-shape-2.svg" alt="shape">
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 order-lg-last">
                        <div class="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                            <p class="text">{{getLang('_dev_text_4') }}</p>
                            <ul>
                                <li>{{getLang('_dev_app_realizzate_1') }}</li>
                                <li>{{getLang('_dev_app_realizzate_2') }}</li>
                                <li>{{getLang('_dev_app_realizzate_3') }}</li>
                                <li>{{getLang('_dev_app_realizzate_12') }}</li>
                                <li>{{getLang('_dev_app_realizzate_4') }}</li>
                                <li>{{getLang('_dev_app_realizzate_5') }}</li>
                                <li>{{getLang('_dev_app_realizzate_6') }}</li>
                                <li>{{getLang('_dev_app_realizzate_7') }}</li>
                                <li>{{getLang('_dev_app_realizzate_8') }} <a href="https://deltatimetracking.com/" target="_blank">{{getLang('_dev_app_realizzate_8_1') }}</a></li>
                                <li>{{getLang('_dev_app_realizzate_9') }}</li>
                                <li>{{getLang('_dev_app_realizzate_10') }}</li>
                                <li>{{getLang('_dev_app_realizzate_13') }}</li>
                                <li>{{getLang('_dev_app_realizzate_14') }}</li>
                                <li>{{getLang('_dev_app_realizzate_15') }}</li>
                                <li>{{getLang('_dev_app_realizzate_11') }}</li>
                            </ul>
                            
                        </div> <!-- about content -->
                    </div>
                    <div class="col-lg-6 order-lg-first">
                        <div class="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                            <img src="images/udraw/dev_tools.svg" alt="shape">
                        </div> <!-- about image -->
                    </div>
                </div> <!-- row -->
            </div> <!-- container -->
        </section>
    </div>
</template>

<script>
export default {
    methods: {
        getLang                         : function(text){
            var _self = this;
            return _self.$root.language_file.[_self.$root.lang][text];
        },
    },
}
</script>