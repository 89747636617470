import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false


//  Import CSS
import "../public/template/css/animate.css";
import "../public/template/css/bootstrap.min.css";
import "../public/template/css/default.css";
import "../public/template/css/style.css";


import "../public/css/custom.css";

// Componenti
Vue.component('Preloader',                                require('./components/Preloader.vue').default);
Vue.component('Header',                                   require('./components/Header.vue').default);
Vue.component('Footbar',                                  require('./components/Footbar.vue').default);
Vue.component('Modal',                                    require('./components/Modal.vue').default);

// Sezioni
Vue.component('Data',                                     require('./views/Data.vue').default);
Vue.component('Dev',                                      require('./views/Dev.vue').default);
Vue.component('Contatti',                                 require('./views/Contatti.vue').default);

// Pagine
Vue.component('Homepage',                                 require('./pages/Homepage.vue').default);

// Lingua Applicativo
import language from './assets/lang.json';


new Vue({
  render: h => h(App),


  // Dati Applicativo
  data() {
    return {
        // Lingua attuale
        lang                        : "EN",
        language_file               : language,

        // Logger Information
        client_ip                   : null,
        scrollResult                : {
          jsonLang      : null,
        },

        // Lingue Disponibili
        available_lang              : [
            "IT",
            "EN"
        ],
      }
  },

}).$mount('#app')
