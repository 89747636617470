<template>
    <div id="contatti" class="cd-section">
        <section class="pt-120" style="position: relative;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="section-title pb-35">
                            <div class="line"></div>
                            <h3 class="title"><span>{{getLang('_contatto_titolo_1_2')}} </span> {{getLang('_contatto_titolo_2_2')}} </h3>
                        </div> <!-- section title -->
                    </div>
                </div> <!-- row -->
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-md-12">
                        <div class="contact-form mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.8s; animation-name: fadeIn;">
                            <div class="form-decoration"></div>
                            <div class="contact-form-invader">
                                <div class="bumper-header">
                                    v
                                    <div class="light1"></div>
                                    <div class="light2"></div>
                                    <div class="light3"></div>
                                    <div class="light4"></div>
                                </div>
                            </div>
                            <div class="contact-form-invader">
                                <div class="bumper-br">h</div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <input type="text" placeholder="Name" v-model="name">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <input type="email" placeholder="Email" v-model="email">
                                    <p class="mail_error" style="display : none;"> You have entered an invalid email address! </p>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <textarea rows="4" placeholder="Notes" v-model="mail_body"></textarea>
                                </div>
                            </div>


                            <ParticleEffectButton
                            :visible.sync="btnOps.visible"
                            :animating.sync="btnOps.animating"
                            :options="btnOps"
                            @click="sendEmail()"
                            cls="btn-cls particles-button"
                            >
                            {{getLang('_contatto_sent_mail')}}
                            </ParticleEffectButton>
                            
                        </div> <!-- contact-form -->
                    </div> 
                </div> <!-- row -->
            </div> <!-- container -->
        </section>
    </div>
</template>

<style scoped>

.mail_error{
    color: #f00000;
}

</style>

<script>

import Swal from 'sweetalert2'
import axios from "axios";
import $ from 'jquery';
import ParticleEffectButton from "vue-particle-effect-buttons"                              // [https://github.com/dreambo8563/vue-particle-effect-buttons]

export default {
    data() {
        return {
            
            btnOps: {
                type: "circle",
                easing: "easeInOutCubic",
                size: 2,
                particlesAmountCoefficient: 3,
                oscillationCoefficient: 5,
                color: function () {
                    return "#c0cd4a";
                },
                onComplete: () => {
                    
                    if(!this.btnOps.visible){
                        this.btnOps.complete = true;
                        this.btnOps.visible = !this.btnOps.visible;
                    }
                    //this.sendEmail();
                },
                onBegin: () => {

                    $(".mail_error").hide();

                    if(!this.ValidateEmail(this.email)){
                        $(".mail_error").show();
                    } else {
                        this.sendEmail()
                    }
                },
                visible: true,
                animating: false,
            },

            name        : '',
            email       : '',
            mail_body   : '',

            mail_time   : true,
        }
    },

    components: {
        ParticleEffectButton
    },

    methods: {
        getLang                         : function(text){
            var _self = this;
            return _self.$root.language_file.[_self.$root.lang][text];
        },

        sendEmail                        : function(){
            
            var _self = this;
            

            if(_self.mail_time){

                _self.mail_time = false;

                console.log(_self.name);
                console.log(_self.email);
                console.log(_self.mail_body);

                var url = "https://www.acidsoft.net/functions/mail.php";

                var params = new URLSearchParams();
                params.append('name', _self.name);
                params.append('mail', _self.email);
                params.append('msg', _self.mail_body);

                axios.post(url , params).then( (response) => {
                    console.log(response)

                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Email sent, we will contact you as soon as possible',
                        showConfirmButton: false,
                        timer: 1500
                    })



                }).catch( (e) => {
                    console.log(e)
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        footer: '<a href="">Why do I have this issue?</a>'
                    })
                });

                setTimeout( function() { _self.mail_time = true;  _self.name  = ""; _self.email = "";  _self.mail_body = ""; } , 5000);
            }
        },

        ValidateEmail                   : function(mail){
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(mail))
                return (true)

            return (false)
        },


    },
}
</script>