<template>
  <div id="app">
    <Preloader />
    <div style="position:relative">
        <Homepage />

        <div id="particles-4" class="c-particles"></div>
    </div>
    <Modal />
  </div>
</template>

<style>

.acid_evidenziato {
    position:relative
}
.acid_evidenziato::before {
    content: '';
    background-color: rgb(218 237 138);
    transform: skewX(160deg);
    position: absolute;
    left: -3px;
    width: 105%;
    height: 105%;
    z-index: -1;
}

</style>

<script>

import $ from 'jquery';

export default {
  
  data() {
    return {
        time                        : 0,
        timer                       : null,
        currentSection              : "data",
        scrollResult                : null,
        sections                    : [
            { name  :   "#contatti" ,   time : 1        ,   href : "contatti"   ,   menu : "Contact Us"     , jsonLang : "_contatto_titolo"},
            { name  :   "#dev"      ,   time : 5        ,   href : "dev"        ,   menu : "Development"    , jsonLang : "_dev_titolo"},
            { name  :   "#analisi"  ,   time : 3600     ,   href : "analisi"    ,   menu : "Data Analysis"  , jsonLang : "_data_titolo"},
        ],
    }
  },  

  mounted() {

    var _self = this;
    
    $.getJSON("https://api.ipify.org/?format=json", function(e) {
        _self.$root.client_ip = e.ip;
    });

    $(window).on('load', function () {
        $('.preloader').fadeOut(500);
        _self.whatchScroll();
    });


    
  },

  methods: {


    /**
    *   Watch sull evento scroll 
    */
    whatchScroll                    : function(){

        var _self = this;

        _self.timer = setInterval(clock, 250);

        function clock(){
            _self.time += 250/1000;
            if(_self.time >= 5){

                clearInterval(_self.timer);
                window.onscroll = null;
                
                _self.$root.scrollResult = { name  :   "#analisi"  , jsonLang : "_data_titolo",  jsonModalLang : "modal_data_titolo" , time : _self.timer};
                _self.logScrollInfo();

                var scrollLink = $('.page-scroll');
                // Active link switching
                $(window).scroll(function () {
                    var scrollbarLocation = $(this).scrollTop();

                    scrollLink.each(function () {

                        var sectionOffset = $(this.hash).offset().top - 73;

                        if (sectionOffset <= scrollbarLocation) {
                            $(this).parent().addClass('active');
                            $(this).parent().siblings().removeClass('active');
                        }
                    });
                });
            }
            console.log(_self.time);
        }

        window.onscroll = function() {
            clearInterval(_self.timer);
            //_self.switchSection();
            
        };
        
    },

    /**
    *   Effettua lo switch della sezione
    */
    switchSection                   : function(){
        var _self = this;

        var _continue = true;

        _self.sections.every(function(section){
            if(_self.time <= section.time){
                _self.scrollResult = { section : section.name , time : _self.time , jsonLang : section.jsonLang , ScrollTime : _self.time };
                _self.$root.scrollResult = { section : section.name , time : _self.time , jsonLang : section.jsonLang, jsonModalLang : "modal" + section.jsonLang, Scrolltime : _self.time };
                $("#section-switcher").append($(section.name));
                _self.logScrollInfo();

                $('a[href$="'+section.href+'"]').parent().remove()

                var li  = $("<li/>" , { class : "nav-item" });
                var a   = $("<a/>"  , { class : "page-scroll" , href : section.name , text : section.menu });

                li.append(a);

                $("#nav li:first-child").after(li);

                _continue = false;
            }
            return _continue;
        });
        
        window.scrollTo({
            top: $("#section-switcher").offset().top - 150,
            behavior: 'smooth',
        });
        
        window.onscroll = null;
        
        var scrollLink = $('.page-scroll');
        // Active link switching
        $(window).scroll(function () {
            var scrollbarLocation = $(this).scrollTop();

            scrollLink.each(function () {

                var sectionOffset = $(this.hash).offset().top - 73;

                if (sectionOffset <= scrollbarLocation) {
                    $(this).parent().addClass('active');
                    $(this).parent().siblings().removeClass('active');
                }
            });
        });

    },

    /**
    *   Logga le info scroll utente log/data
    */
    logScrollInfo                   : function(){

        var _self = this;

        $.ajax({
            url: './functions/logger.php', 
            data: {
                scrollTime  : _self.$root.scrollResult.time,
                sezione     : _self.$root.language_file.["IT"][_self.$root.scrollResult.jsonLang],
                lang        : _self.$root.lang,
                ip          : _self.$root.client_ip,
            },
            type: 'POST',

            success: function (data) {
                console.log(data);
            },
            error : function (e){
                console.log(e);
            }
        })
    },

  },

}
</script>